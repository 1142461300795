import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import Seo from './../components/Layout/Seo'
import Title from './../components/Title'
import InstagramFeed from './../components/Instagram/InstagramFeed'
import ButtonCircle from '../components/Buttons/ButtonCircle'
import SectionBanner from "../components/SectionBanner"
import IconCalendar from "./../graphics/home/icon-calendar.svg"

const Wrapper = styled.section`
    position: relative;

    padding: 10rem 0 0;

    ${props => props.theme.above.desktop`
        padding: 20rem 0 0;
    `}
`

const StyledTitle = styled(Title)`
    ${props => props.theme.above.desktop`
        margin-bottom: 12rem;

        .line {
            display: none;
        }
    `}
`

const Grid = styled.ul`
    width: 100%;
    max-width: 115rem; 
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;

    ${props => props.theme.below.desktop`
        grid-template-columns: repeat(1, 1fr);
        padding: 2.5rem;
    `}
`

const GridIcon = styled(IconCalendar)`
    width: 5rem;

    margin-bottom: 2rem;
`

const GridItem = styled.li`
    background-color: #242424;
    padding: 5rem 4rem;

    text-align: center;
`

const GridTitle = styled.h4`
    margin-bottom: 1rem;
`

const GridDescription = styled.div``

const Text = styled.div`
    padding: 7rem 0;
    text-align: center;

    ${props => props.theme.below.desktop`
        padding: 4rem 4rem 6rem 4rem;
    `}
`

const Disclaimer = styled.div`
    margin-bottom: 2.5rem;

    a {
        font-family: ${props => props.theme.fontFamily.neueMontrealMedium};
        text-decoration: underline;
        font-size: 1em;
    }
`

const StyledBanner = styled(SectionBanner)`
    margin: 10rem 0 0 0;
    
    ${props => props.theme.below.desktop`
        margin: 0;
    `}
`

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 2.5rem;
    }

    .button-circle {
        display: inline-block;
    }

    ${props => props.theme.below.desktop`
        text-align: left;

        .button-circle {
            margin-bottom: 2.5rem;
            padding: 0 2.5rem;
        }
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 15rem;
    `}
`

const ColoredSection = styled.section`
    padding: 15rem 0;

    background-color: #242424;

    ${props => props.theme.below.desktop`
        padding: 6rem 0 0 0;
    `}
`

const Step = styled.li`
    display: flex;
    flex-direction: column;

    background-color: #313030;

    text-align: left;
`

const StepStep = styled.span`
    display: inline-block;

    border: 1px solid #949494;
    
    margin-bottom: 2rem;
    padding: 0.5rem 1rem 0.5rem 1.25rem;

    text-transform: uppercase;

    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.5rem;

    border-radius: 5rem;
`

const StepContent = styled.div`
    padding: 3rem;
    flex-grow: 1;
`

const StepTitle = styled.h4`
    margin-bottom: 1rem;
`

const StepDescription = styled.div`
    p {
        color: #fff;
    }
`

const StepImage = styled(Img)`
    width: 100%;
`

const NormalSection = styled.section`
    padding: 15rem 0;

    ${props => props.theme.below.desktop`
        padding: 6rem 0 0 0;
    `}
`

const LightSection = styled.section`
    padding: 15rem 0;

    background-color: #313030;

    ${props => props.theme.below.desktop`
        padding: 4rem 0;
    `}
`

const Testimonial = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 5rem;

    background-color: #1A1818;

    text-align: center;
    
    height: auto !important;
`

const TestimonialStarsWrapper = styled.h4`
    margin-bottom: 1rem;

    color: #fff;
`

const TestimonialStar = styled.span`
    color: #fff;
`

const TestimonialDescription = styled.div`
    margin-bottom: 1rem;
`

const TestimonialAuthor = styled.h5``

let stars = ['★', '★', '★', '★', '★']

const WarrantyPage = ({
    data: {
        instagram,
        waranty: {
            data: {
                content_title,
                content_description,
                conditions_list,
                conditions_subtitle,
                conditions_button_label,
                conditions_button_link,
                banner_image,
                fixit_title,
                fixit_description,
                fixit_button_label,
                fixit_button_link,
                fixit_steps,
                coverage_title,
                coverage_description,
                coverage_list,
                testimonials_list,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image
            }
        }
    }
}) => {
    const handleOnDragStart = e => {
        e.preventDefault()
    }

    const params = {
        centeredSlides: true,
        initialSlide: 1,
        loop: true,
        preloadImages: false,
        grabCursor: true,
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            800: {
                slidesPerView: 4,
            },
            1600: {
                slidesPerView: 5,
            },
            2500: {
                slidesPerView: 7,
            },
        },
    }

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Wrapper>
                <StyledTitle 
                    title={content_title.text}
                    description={content_description.text}
                    animation="after"
                    alignment='center'
                    text
                />
                <Grid>
                    {conditions_list.map((item, i) => {
                        return (
                            <GridItem key={i}>
                                {item.condition_item_icon.fluid ? <Img fluid={item.condition_item_icon.fluid} alt={item.condition_item_icon.alt} /> : <GridIcon />}
                                <GridTitle>{item.condition_item_title.text}</GridTitle>
                                <GridDescription dangerouslySetInnerHTML={{ __html: item.condition_item_description.html }}></GridDescription>
                            </GridItem>
                        )
                    })}
                </Grid>
                <Text>
                    <Disclaimer dangerouslySetInnerHTML={{ __html: conditions_subtitle.html }}></Disclaimer>
                    <ButtonCircle type='arrow' direction='right' label={conditions_button_label} href={conditions_button_link.url} />
                </Text>
                <StyledBanner data={banner_image} showImageTitle />
                <ColoredSection>
                    <TitleWithButtonWrapper>
                        <Title
                            title={fixit_title.text}
                            description={fixit_description.text}
                            animation="after"
                            alignment="center"
                            text
                        />
                        <ButtonCircle
                            type="arrow"
                            direction="right"
                            label={fixit_button_label}
                            animation="after"
                            href={fixit_button_link.url}
                        />
                    </TitleWithButtonWrapper>
                    <Grid>
                        {fixit_steps.map((item, i) => {
                            return (
                                <Step key={i}>
                                    <StepContent>
                                        <StepStep>Step {`0${i}`}</StepStep>
                                        <StepTitle>{item.fixit_step_title.text}</StepTitle>
                                        <StepDescription dangerouslySetInnerHTML={{ __html: item.fixit_step_description.html }}></StepDescription>
                                    </StepContent>
                                    <StepImage fluid={item.fixit_step_image.fluid} alt={item.fixit_step_image.alt} />
                                </Step>
                            )
                        })}
                    </Grid>
                </ColoredSection>
                <NormalSection>
                    <StyledTitle 
                        title={coverage_title.text}
                        description={coverage_description.text}
                        animation="after"
                        alignment='center'
                        text
                    />
                    <Grid>
                        {coverage_list.map((item, i) => {
                            return (
                                <GridItem key={i}>
                                    {item.coverage_item_icon.fluid ? <Img fluid={item.coverage_item_icon.fluid} alt={item.coverage_item_icon.alt} /> : <GridIcon />}
                                    <GridTitle>{item.coverage_item_title.text}</GridTitle>
                                    <GridDescription dangerouslySetInnerHTML={{ __html: item.coverage_item_description.html }}></GridDescription>
                                </GridItem>
                            )
                        })}
                    </Grid>
                </NormalSection>
                <LightSection>
                    <Swiper {...params}>
                        {testimonials_list.map((item, index) => {
                            const {
                                testimonial_item_description,
                                testimonial_item_name,
                                testimonial_item_stars_count
                            } = item
                            return (
                                <Testimonial
                                    key={index}
                                    onDragStart={handleOnDragStart}
                                >
                                    <TestimonialStarsWrapper>
                                        {stars.slice(0, parseInt(testimonial_item_stars_count)).map((item, i) => {
                                            return (
                                                <TestimonialStar key={i}>{item}</TestimonialStar>
                                            )
                                        })}
                                    </TestimonialStarsWrapper>
                                    <TestimonialDescription dangerouslySetInnerHTML={{ __html: testimonial_item_description.html }}></TestimonialDescription>
                                    <TestimonialAuthor>{testimonial_item_name.text}</TestimonialAuthor>
                                </Testimonial>
                            )
                        })}
                    </Swiper>
                </LightSection>
            </Wrapper>
            <InstagramFeed
                copy={instagram}
            />
        </>
    )
}

export const QUERY_WARRANTYPAGE = graphql`
    query WarrantyPage($id: String!, $lang: String!) {
        waranty: prismicWarrantyPage(id: { eq: $id }) {
            id
            lang
            ...WarrantyPageQuery
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default WarrantyPage